import React from 'react'
import { Trans, t } from '@lingui/macro'
import Link from 'next/link'
import Router from 'next/router'
import { observer } from 'mobx-react-lite'
import {
  Bar,
  BarItem,
  ButtonDropdown,
  ButtonLink,
  Column,
  DropdownItem,
  Table,
} from '../../../common/ui-kit'
import { DeliveryContent, ModalAddDepartment } from './ModalAddDepartment'
import { StoreDepartments, TDepartmentTableRow } from './StoreDepartments'
import { _, i18n } from '../../../common/utils/intl'

import { ApiClientsRepository } from '../../../common/api/ApiClientsRepository'
import { ReachConfirmDialog } from '../../../common/modules/modals/ConfirmModal'
import { TableActionsFirst } from './TableActionsFirst'
import { TableActionsSecond } from './TableActionsSecond'
import { TableNotice } from '../../../common/modules/data-table/TableNotice'
import { TablePagination } from '../../../common/modules/data-table/TablePagination'
import { WidgetNoDataContainer } from '../../../common/modules/data-table/WidgetNoDataContainer'
import { useDialog } from '../../../common/utils/hooks/useDialog'

const MODAL_CONFIRM_ID = 'dialog-confirm-delete'

const RemoveDepartmentDropdownItem: React.FC<{
  id: string
  state: StoreDepartments
}> = ({ state, id }) => {
  const d = useDialog()
  return (
    <>
      <DropdownItem
        data-testid="btn-remove"
        onClick={() => {
          d.open()
        }}>
        <Trans>Odstrániť</Trans>
      </DropdownItem>
      {d.isOpen && (
        <ReachConfirmDialog
          close={d.close}
          id={MODAL_CONFIRM_ID}
          buttonConfirmAction={async () => {
            try {
              await ApiClientsRepository.deleteDepartment(state.clientId, id)
              state.reloadRef = {}
              d.close()
            } catch (err) {}
          }}
          message={i18n.t`Naozaj chcete oddelenie odstrániť?`}
          buttonConfirmText={i18n.t`Odstrániť`}
        />
      )}
    </>
  )
}

const columnsTemplate: Column<TDepartmentTableRow>[] = [
  {
    key: 'code',
    label: '#',
    thClasses: 'table__col--shrink',
    tdClasses: 'table__col--shrink text-nowrap',
  },
  {
    key: 'name',
    label: _(t`Názov oddelenia`),
    thClasses: 'table__col--auto',
    tdClasses: 'table__col--auto text-bold',
    render(data) {
      return (
        <Link href={{ pathname: `${Router.pathname}/department-detail`, query: { id: data.id } }}>
          <a>{data.name}</a>
        </Link>
      )
    },
  },
  /* {
    key: 'number_of_employees',
    label: (
      <Tooltip
        content={<Icon name="employees" size="medium" className="text-vam" />}
        dialog={_(t`Počet zamestnancov`)}
      />
    ),
    thClasses: 'table__col--shrink text-center',
    tdClasses: 'table__col--shrink text-center',
  }, */
  {
    key: 'detail',
    label: _(t`Detail`),
    thClasses: 'table__col--shrink text-center',
    tdClasses: 'table__col--shrink text-center',
    noSort: true,
    render(data) {
      return (
        <Bar className="align-items-right mb-none" nowrap>
          <BarItem>
            <ButtonLink
              space="condensed"
              size="tiny"
              className="mb-none"
              data-testid="btn-navigate"
              onClick={() => {
                Router.push({
                  pathname: `${Router.pathname}/department-detail`,
                  query: {
                    id: data.id,
                  },
                })
              }}>
              <Trans>Detail</Trans>
            </ButtonLink>
          </BarItem>
        </Bar>
      )
    },
  },
  {
    key: 'actions',
    label: _(t`Akcie`),
    thClasses: 'table__col--shrink text-center',
    tdClasses: 'table__col--shrink text-center',
    noSort: true,
    render(data) {
      return (
        <ButtonDropdown
          icon
          iconName="dots"
          iconRight={false}
          iconSize="medium"
          iconToggle="dots"
          type="link-secondary"
          equal
          size="tiny"
          className="mb-none">
          <RemoveDepartmentDropdownItem {...data} />
        </ButtonDropdown>
      )
    },
  },
]

type DepartmentsTableProps = {
  store: StoreDepartments
}

export const TableDepartments = observer(({ store: _store }: DepartmentsTableProps) => {
  const store = _store
  const { pagination, sorting, selecting } = store
  const d = useDialog()

  return (
    <>
      <TableActionsFirst store={store} />
      <TableActionsSecond store={store} />
      <Table<TDepartmentTableRow>
        data-testid="table-departments"
        notice={<TableNotice selecting={selecting} isFiltered={store.filters.isAnyFilterActive} />}
        sortable
        selectable
        rowKey="id"
        columns={columnsTemplate}
        data={store.data}
        sortedColumns={sorting.sortDefsAsRecord}
        sortColumn={sorting.setColumnSortDir}
        onAllRowsSelect={selecting.handlePageRowsSelect}
        onRowSelect={selecting.handleRowSelect}
        selected={selecting.selected}
        allPageRowsSelected={!!selecting.allPageRowsSelected}
        showLoading
        loading={store.loadingDepartments}
        {...(store.hasNoData && {
          emptyTableMarkup: (
            <WidgetNoDataContainer>
              <div>
                <Trans>Nemáte zadané žiadne oddelenia</Trans>
              </div>
              <div style={{ marginTop: 8 }}>
                <a onClick={d.open} className="btn btn--small btn--condensed btn--link">
                  <Trans>Pridajte prvé oddelenie</Trans>
                </a>
              </div>
            </WidgetNoDataContainer>
          ),
        })}
        {...(store.isFilteredAndNoData && {
          emptyTableMarkup: (
            <WidgetNoDataContainer>
              <Trans>Neboli nájdené žiadne vyhovujúce dáta k daným filtrom</Trans>
            </WidgetNoDataContainer>
          ),
        })}
      />
      <TablePagination store={pagination} extendedOptions />

      {d.isOpen && (
        <ModalAddDepartment
          close={d.close}
          onSave={() => {
            store.reloadRef = {}
          }}>
          {({ form }) => <DeliveryContent form={form} />}
        </ModalAddDepartment>
      )}
    </>
  )
})
