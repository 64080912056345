import React, { useContext } from 'react'
import { Trans, t } from '@lingui/macro'
import Link from 'next/link'
import Router from 'next/router'
import { observer } from 'mobx-react-lite'
import {
  Bar,
  BarItem,
  ButtonDropdown,
  ButtonLink,
  Column,
  DropdownItem,
  Grid,
  GridCol,
  Table,
} from '../../../common/ui-kit'
import { DeliveryContent, ModalAddAddress } from './ModalAddAddress'
import { StoreAddresses, TDeliveryAddressTableRow } from './StoreAddresses'
import { _, i18n } from '../../../common/utils/intl'
import { formatPhone, formatZip } from '../../../common/utils/format'

import { ApiClientsRepository } from '../../../common/api/ApiClientsRepository'
import { ClientCtx } from '../../ClientStore'
import { ReachConfirmDialog } from '../../../common/modules/modals/ConfirmModal'
import { TableActionsFirst } from './TableActionsFirst'
import { TableActionsSecond } from './TableActionsSecond'
import { TableNotice } from '../../../common/modules/data-table/TableNotice'
import { TablePagination } from '../../../common/modules/data-table/TablePagination'
import { WidgetNoDataContainer } from '../../../common/modules/data-table/WidgetNoDataContainer'
import { appStore } from '../../../common/AppStore'
import { useDialog } from '../../../common/utils/hooks/useDialog'

const MODAL_CONFIRM_ID = 'dialog-confirm-delete'

const RemoveAddressDropdownItem: React.FC<{
  id: string
  state: StoreAddresses
}> = ({ state, id }) => {
  const d = useDialog()
  return (
    <>
      <DropdownItem
        data-testid="btn-remove"
        onClick={() => {
          d.open()
        }}>
        <Trans>Odstrániť</Trans>
      </DropdownItem>
      {d.isOpen && (
        <ReachConfirmDialog
          close={d.close}
          id={MODAL_CONFIRM_ID}
          buttonConfirmAction={async () => {
            try {
              await ApiClientsRepository.deleteDeliveryAddress(state.clientId, state.branchId, id)
              state.reloadRef = {}
              d.close()
            } catch (err) {}
          }}
          message={i18n.t`Naozaj chcete adresu odstrániť?`}
          buttonConfirmText={i18n.t`Odstrániť`}
        />
      )}
    </>
  )
}

const columnsTemplate: Column<TDeliveryAddressTableRow>[] = [
  {
    key: 'code',
    label: '#',
    thClasses: 'table__col--shrink',
    tdClasses: 'table__col--shrink text-nowrap',
  },
  {
    key: 'name',
    label: _(t`Názov adresy`),
    thClasses: 'table__col--auto',
    tdClasses: 'table__col--auto text-bold',
    render(data) {
      return (
        <Link href={{ pathname: `${Router.pathname}/address-detail`, query: { id: data.id } }}>
          <a>{data.name}</a>
        </Link>
      )
    },
  },
  /* {
    key: 'number_of_employees',
    label: (
      <Tooltip
        content={<Icon name="employees" size="medium" className="text-vam" />}
        dialog={_(t`Počet zamestnancov`)}
      />
    ),
    thClasses: 'table__col--shrink text-center',
    tdClasses: 'table__col--shrink text-center',
  }, */
  {
    key: 'address.street',
    label: _(t`Adresa`),
    thClasses: 'table__col--auto',
    tdClasses: 'table__col--auto',
    render(i) {
      const { address } = i.raw
      return (
        <Grid>
          <GridCol>{address.street}</GridCol>
          <GridCol>{formatZip(address.postcode!)}</GridCol>
          <GridCol>{address.city}</GridCol>
        </Grid>
      )
    },
  },
  {
    key: 'contact_person.last_name',
    label: _(t`Kontaktná osoba`),
    thClasses: 'table__col--auto',
    tdClasses: 'table__col--auto',
    render(i) {
      const { contact_person } = i.raw
      return (
        <Grid>
          <GridCol>
            {contact_person.last_name} {contact_person.first_name}
          </GridCol>
          <GridCol>{contact_person.email}</GridCol>
          <GridCol>{formatPhone(contact_person.phone!)}</GridCol>
        </Grid>
      )
    },
  },

  {
    key: 'detail',
    label: _(t`Detail`),
    thClasses: 'table__col--shrink text-center',
    tdClasses: 'table__col--shrink text-center',
    noSort: true,
    render(data) {
      return (
        <Bar className="align-items-right mb-none" nowrap>
          <BarItem>
            <ButtonLink
              space="condensed"
              size="tiny"
              className="mb-none"
              data-testid="btn-navigate"
              onClick={() => {
                Router.push({
                  pathname: `${Router.pathname}/address-detail`,
                  query: {
                    id: data.id,
                  },
                })
              }}>
              <Trans>Detail</Trans>
            </ButtonLink>
          </BarItem>
        </Bar>
      )
    },
  },
  {
    key: 'actions',
    label: _(t`Akcie`),
    thClasses: 'table__col--shrink text-center',
    tdClasses: 'table__col--shrink text-center',
    noSort: true,
    render(data) {
      return (
        <ButtonDropdown
          data-testid="btn-actions"
          icon
          iconName="dots"
          iconRight={false}
          iconSize="medium"
          iconToggle="dots"
          type="link-secondary"
          equal
          size="tiny"
          className="mb-none">
          <RemoveAddressDropdownItem {...data} />
        </ButtonDropdown>
      )
    },
  },
]

type TableAddressesProps = {
  store: StoreAddresses
}

export const TableAddresses = observer(({ store }: TableAddressesProps) => {
  const { pagination, sorting, selecting } = store
  const dialogAddAdress = useDialog()
  const clientDetail = useContext(ClientCtx)
  const isUserMaster = appStore.getIsUserMasterFromId(clientDetail.id)

  return (
    <>
      <TableActionsFirst addressesStore={store} />
      <TableActionsSecond addressesStore={store} />
      <Table<TDeliveryAddressTableRow>
        notice={<TableNotice selecting={selecting} isFiltered={store.filters.isAnyFilterActive} />}
        data-testid="table-addresses"
        sortable
        selectable
        rowKey="id"
        columns={columnsTemplate}
        data={store.data}
        sortedColumns={sorting.sortDefsAsRecord}
        sortColumn={val => sorting.setColumnSortDir(val)}
        onAllRowsSelect={selecting.handlePageRowsSelect}
        onRowSelect={selecting.handleRowSelect}
        selected={selecting.selected}
        allPageRowsSelected={!!selecting.allPageRowsSelected}
        showLoading
        loading={store.addressesLoading}
        {...(store.hasNoData && {
          emptyTableMarkup: (
            <WidgetNoDataContainer>
              <div>
                <Trans>Nemáte zadané žiadne adresy doručenia</Trans>
              </div>
              <div style={{ marginTop: 8 }}>
                {isUserMaster && (
                  <a
                    onClick={dialogAddAdress.open}
                    className="btn btn--small btn--condensed btn--link">
                    <Trans>Pridajte prvú adresu</Trans>
                  </a>
                )}
              </div>
              {dialogAddAdress.isOpen && (
                <ModalAddAddress
                  close={dialogAddAdress.close}
                  country={store.country!}
                  onSave={() => {
                    store.reloadRef = {}
                  }}>
                  {({ form }) => <DeliveryContent form={form} />}
                </ModalAddAddress>
              )}
            </WidgetNoDataContainer>
          ),
        })}
        {...(store.isFilteredAndNoData && {
          emptyTableMarkup: (
            <WidgetNoDataContainer>
              <Trans>Neboli nájdené žiadne vyhovujúce dáta k daným filtrom</Trans>
            </WidgetNoDataContainer>
          ),
        })}
      />
      <TablePagination store={pagination} extendedOptions />
    </>
  )
})
