import React from 'react'

import { Trans } from '@lingui/macro'
import { useRouter } from 'next/router'
import { useDidMount } from '../../../src/common/utils/hooks/useDidMount'
import { SCOPES } from '../../../src/config/constants'
import { Authorized } from '../../../src/common/modules/auth/Authorized'
import { FetchClient } from '../../../src/client/FetchClient'
import { PageManageAddresses } from '../../../src/client/employees-and-addresses/addresses/PageManageAddresses'
import { PageManageDepartments } from '../../../src/client/employees-and-addresses/departments/PageManageDepartments'
import { Container, Section, TabLink, TabPanel, Tabs } from '../../../src/common/ui-kit'

export enum TABS_NAMES {
  addresses = 'addresses',
  departments = 'departments',
  employees = 'employees',
}

export const TABS_INDEXES_BY_NAME = new Map([
  [TABS_NAMES.addresses, 0],
  [TABS_NAMES.departments, 1],
])

export const TABS_NAMES_BY_INDEX = new Map([
  [0, TABS_NAMES.addresses],
  [1, TABS_NAMES.departments],
])

export const removeSavedFiltersFromStorage = () => {
  sessionStorage.removeItem('department')
  sessionStorage.removeItem('deliveryAddress')
  sessionStorage.removeItem('active')
  sessionStorage.removeItem('cities')
  sessionStorage.removeItem('dep.search')
  sessionStorage.removeItem('addr.search')
  sessionStorage.removeItem('addr.deliveryAddress')
  sessionStorage.removeItem('search')
}

export const Page = (props: { tab: string }) => {
  const { tab } = props
  const activeTabIndex = TABS_INDEXES_BY_NAME.get(tab as TABS_NAMES)
  const router = useRouter()

  useDidMount(() => {
    const handleRouteChange = (url: any) => {
      if (!url.includes('client/addresses-and-departments')) {
        removeSavedFiltersFromStorage()
      }
    }
    router.events.on('routeChangeStart', handleRouteChange)

    return () => {
      router.events.off('routeChangeStart', handleRouteChange)
    }
  })

  return (
    <Authorized scope={SCOPES.Client}>
      <FetchClient>
        <Container>
          <Section size="xxlarge">
            <h1 className="h2 mb-large">
              <Trans>Adresy a oddelenia</Trans>
            </h1>
            <Tabs
              activeTabIndex={activeTabIndex}
              onChange={(activeTab, _oldActiveTab, _e) => {
                const tabName = TABS_NAMES_BY_INDEX.get(activeTab)

                router.push({
                  pathname: router.pathname,
                  query: {
                    tab: tabName,
                  },
                })

                if (Object.values(TABS_NAMES).includes(tab as TABS_NAMES)) {
                  removeSavedFiltersFromStorage()
                }
              }}>
              <TabPanel
                tab={
                  <TabLink size="medium" id="tab-branches" data-testid="tab-branches" href="#tab0">
                    <Trans>Adresy</Trans>
                  </TabLink>
                }>
                {activeTabIndex === 0 && <PageManageAddresses />}
              </TabPanel>
              <TabPanel
                tab={
                  <TabLink
                    size="medium"
                    id="tab-departments"
                    data-testid="tab-departments"
                    href="#tab1">
                    <Trans>Oddelenia</Trans>
                  </TabLink>
                }>
                {activeTabIndex === 1 && <PageManageDepartments />}
              </TabPanel>
            </Tabs>
          </Section>
        </Container>
      </FetchClient>
    </Authorized>
  )
}

export default () => {
  const router = useRouter()
  const { tab: _tab } = router.query
  let tab = _tab

  if (Array.isArray(tab)) {
    // eslint-disable-next-line prefer-destructuring
    tab = tab[0]
  }

  if (!tab) {
    tab = TABS_NAMES.addresses
  }

  return <Page tab={tab} />
}
