import React from 'react'
import ContentLoader from 'react-content-loader'
import { Loader } from '../../../common/ui-kit'

export const PageManageAddressesSkeleton = () => {
  return (
    <>
      <Loader
        size="large"
        loaderWrapperProps={{
          style: {
            position: 'absolute',
            top: '200px',
            justifyContent: 'center',
            width: '100%',
          },
        }}
      />

      <ContentLoader
        height={760}
        width={1190}
        speed={2}
        primaryColor="#f3f3f3"
        secondaryColor="#ecebeb">
        <rect x="0" y="20" width="150" height="25" rx="3" ry="3" />
        <rect x="0" y="65" width="125" height="40" rx="3" ry="3" />
        <rect x="145" y="65" width="170" height="40" rx="3" ry="3" />

        <rect x="0" y="120" width="330" height="30" rx="3" ry="3" />
        <rect x="970" y="120" width="220" height="30" rx="3" ry="3" />

        <rect x="0" y="165" width="1190" height="45" rx="3" ry="3" />
        <rect x="0" y="215" width="1190" height="45" rx="3" ry="3" />
        <rect x="0" y="265" width="1190" height="45" rx="3" ry="3" />
        <rect x="0" y="315" width="1190" height="45" rx="3" ry="3" />
        <rect x="0" y="365" width="1190" height="45" rx="3" ry="3" />
        <rect x="0" y="415" width="1190" height="45" rx="3" ry="3" />
        <rect x="0" y="465" width="1190" height="45" rx="3" ry="3" />
        <rect x="0" y="515" width="1190" height="45" rx="3" ry="3" />
        <rect x="0" y="565" width="1190" height="45" rx="3" ry="3" />
        <rect x="0" y="615" width="1190" height="45" rx="3" ry="3" />
        <rect x="0" y="665" width="1190" height="45" rx="3" ry="3" />
        <rect x="0" y="715" width="1190" height="45" rx="3" ry="3" />
        <rect x="0" y="765" width="1190" height="45" rx="3" ry="3" />
        <rect x="0" y="815" width="1190" height="45" rx="3" ry="3" />
      </ContentLoader>
    </>
  )
}
