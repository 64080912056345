/* eslint-disable no-await-in-loop */
import React, { useRef } from 'react'
import { useLocalStore, observer } from 'mobx-react-lite'
import { Trans } from '@lingui/macro'
import { i18n } from '../../../common/utils/intl'
import {
  DialogHeader,
  DialogContent,
  DialogFooter,
  Bar,
  BarItem,
  Grid,
  GridCol,
  Button,
  ButtonLink,
  ButtonPrimary,
  ButtonLayout,
  Icon,
  FileUpload,
  FINE_UPLOADER_EXCEL,
  ReachDialog,
} from '../../../common/ui-kit'
import { StoreAddresses } from './StoreAddresses'
import { to } from '../../../common/utils/awaitTo'
import { extractFileFromFineUploader } from '../../../common/utils/extractFilesFromFineUploader'
import { ApiClientsRepository } from '../../../common/api/ApiClientsRepository'
import { downloadBlob } from '../../../common/utils/downloadToDisk'
import { clientStore } from '../../ClientStore'
import { extractAllMessagesFromAxiosError } from '../../../common/utils/extractMessageFromAxiosError'

export const ModalImportAddresses: React.FC<{
  store: StoreAddresses
  close: () => void
  onSave?: () => void
  onImportFail: (errorMessages: []) => void
}> = observer(({ close, onSave, onImportFail }) => {
  const uploaderRef = useRef<any>()
  const { clientId, branchId } = clientStore

  const state = useLocalStore(() => ({
    isUploading: false,
    hasFile: false,
    downloadTemplateLoading: false,
  }))

  return (
    <ReachDialog id={ModalImportAddressesID} onDismiss={close}>
      <DialogHeader title={i18n.t`Import údajov`} />
      <DialogContent>
        <Bar direction="vertical">
          <BarItem>
            <Grid>
              <GridCol size={2}>
                <div className="text-counter">1</div>
              </GridCol>
              <GridCol size={9}>
                <h3 className="h5">
                  <Trans>Stiahnutie</Trans>
                </h3>
                <p className="text-color-grey-600">
                  <Trans>
                    Stiahnite si vzorový súbor a vyplňte do neho údaje o vašich doručovacích
                    adresách
                  </Trans>
                </p>
                <ButtonLink
                  size="small"
                  space="condensed"
                  isLoading={state.downloadTemplateLoading}
                  isDisabled={state.downloadTemplateLoading}
                  onClick={async () => {
                    try {
                      state.downloadTemplateLoading = true
                      const response = await ApiClientsRepository.fetchAddressesImportTemplate(
                        clientId,
                        branchId
                      )
                      downloadBlob(response)
                      state.downloadTemplateLoading = false
                    } catch (err) {
                      state.downloadTemplateLoading = false
                    }
                  }}>
                  {!state.downloadTemplateLoading && (
                    <Icon name="download" size="medium" className="icon--left" />
                  )}
                  <span>
                    <Trans>Stiahnuť vzorový súbor</Trans>
                  </span>
                </ButtonLink>
              </GridCol>
            </Grid>
          </BarItem>
          <BarItem>
            <Grid>
              <GridCol size={2}>
                <div className="text-counter">2</div>
              </GridCol>
              <GridCol size={9}>
                <h3 className="h5">
                  <Trans>Nahratie</Trans>
                </h3>
                <p className="text-color-grey-600">
                  <Trans>
                    Po vyplnení vzorového súboru ho môžete nahrať sem. Po jeho úspešnom potvrdení sa
                    vytvoria nové adresy
                  </Trans>
                </p>
                <FileUpload
                  uploaderRef={uploaderRef}
                  id="branch-import"
                  // !!! when this callback is not supplied, app runs out of memory :-DDD
                  onStateChange={val => {
                    state.hasFile = val.fileId != null
                  }}
                  fineUploaderOptions={{
                    validation: {
                      ...FINE_UPLOADER_EXCEL,
                    },
                  }}
                />
              </GridCol>
            </Grid>
          </BarItem>
        </Bar>
      </DialogContent>
      <DialogFooter>
        <ButtonLayout direction="horizontal" className="align-items-right">
          <Button onClick={close}>
            <Trans>Zatvoriť</Trans>
          </Button>
          <ButtonPrimary
            isDisabled={state.isUploading || !state.hasFile}
            isLoading={state.isUploading}
            onClick={async () => {
              const file = await extractFileFromFineUploader(uploaderRef.current)

              if (!file) return
              // should be just 1 file

              state.isUploading = true

              const [err] = await to(
                ApiClientsRepository.importDeliveryAddressesFromFile(
                  clientId,
                  branchId,
                  file,
                  false
                )
              )

              if (!err) {
                if (onSave) onSave()
                close()
              } else {
                onImportFail(extractAllMessagesFromAxiosError(err))
              }

              state.isUploading = false
            }}>
            <Trans>Potvrdiť</Trans>
          </ButtonPrimary>
        </ButtonLayout>
      </DialogFooter>
    </ReachDialog>
  )
})

ModalImportAddresses.displayName = 'ModalImportAddresses'

export const ModalImportAddressesID = 'dialog-import-addresses'
