import React, { useState } from 'react'
import { Trans } from '@lingui/react'
import { Grid, GridCol, ButtonLayout, Button, Icon } from '../../../common/ui-kit'
import { ModalAddDepartment, DeliveryContent, ModalAddDepartmentID } from './ModalAddDepartment'
import { StoreDepartments } from './StoreDepartments'
import { SHOW_ADD_DEPARTMENT_EVENT } from './constants'
import { useListener } from '../../../common/utils/hooks/useGlobalBus'
import { useDialog } from '../../../common/utils/hooks/useDialog'
import { ModalImportDepartments } from '../employees/ModalImportDepartments'
import { ReachConfirmDialog } from '../../../common/modules/modals/ConfirmModal'
import { i18n } from '../../../common/utils/intl'

type TableActionsFirstProps = {
  store: StoreDepartments
}

export function TableActionsFirst({ store }: TableActionsFirstProps) {
  const d = useDialog()
  const imp = useDialog()

  const modalError = useDialog()
  const [importErrorMessages, setImportErrorMessages] = useState([])

  const [exportLoading, setExportLoading] = useState(false)

  useListener(SHOW_ADD_DEPARTMENT_EVENT, () => {})

  const handleDownload = async () => {
    setExportLoading(true)
    if (store.selecting.selected.length) {
      await store.fetchClientDepartmentsExportBulk().then(() => {
        setExportLoading(false)
      })
    } else {
      await store.fetchClientDepartmentsExport().then(() => {
        setExportLoading(false)
      })
    }
  }

  return (
    <Grid>
      <GridCol size="fill">
        <ButtonLayout key="actions-left-1" className="mb" direction={{ s: 'horizontal' }}>
          <Button onClick={d.open} data-testid={ModalAddDepartmentID}>
            <Icon name="plus" size="medium" className="icon--left" />
            <Trans>Pridať</Trans>
          </Button>
          <Button onClick={imp.open}>
            <Icon name="upload" size="medium" className="icon--left" />
            <Trans>Importovať</Trans>
          </Button>
          <Button loaderProps={{ white: false }} isLoading={exportLoading} onClick={handleDownload}>
            <Icon name="download" size="medium" className="icon--left" />
            <Trans>Stiahnuť</Trans>
          </Button>
        </ButtonLayout>
      </GridCol>

      {d.isOpen && (
        <ModalAddDepartment
          close={d.close}
          onSave={() => {
            store.reloadRef = {}
          }}>
          {({ form }) => <DeliveryContent form={form} />}
        </ModalAddDepartment>
      )}

      {imp.isOpen && (
        <ModalImportDepartments
          close={imp.close}
          onFinish={() => {
            store.reloadRef = {}
          }}
          onImportFail={async errorMessages => {
            imp.close()
            setImportErrorMessages(errorMessages)
            modalError.open()
          }}
        />
      )}

      {modalError.isOpen && (
        <ReachConfirmDialog
          close={modalError.close}
          id="modal-import-error"
          buttonConfirmAction={async () => {
            modalError.close()
            imp.open()
          }}
          message={
            <>
              <p>{i18n.t`Import neprebehol úspešne.`}</p>
              {!!(importErrorMessages.length > 0) &&
                importErrorMessages.map((message: { message: string }) => <p>{message.message}</p>)}
            </>
          }
          buttonConfirmText={i18n.t`Nahrať znovu`}
        />
      )}
    </Grid>
  )
}
