import { DeliveryAddress, Country } from '@odiupsk/up-api-client'
import { observable, computed } from 'mobx'
import { clientsApi, countriesApi } from '../../../common/api'
import { FilterExpressionBuilder } from '../../../common/utils/filterExpressionBuilder'
import { TablePaginationStore } from '../../../common/modules/data-table/stores/TablePaginationStore'
import { get } from '../../../common/utils/get'
import { TableSortStore } from '../../../common/modules/data-table/stores/TableSortStore'
import { errorDuringFetch } from '../../../common/utils/i18n/alerts'
import { UnwrapFromArray } from '../../../common/utils/types'
import { logError } from '../../../common/api/utils'
import { DeliveryAddressesStore } from '../CommonDataFetchStores'
import { TableSelectRowsStore } from '../../../common/modules/data-table/stores/TableSelectRowsStore'
import { maxPerPage } from '../../../common/utils/maxPerPage'

export const transformDataForTableRows = (
  deliveryAddresses: DeliveryAddress[],
  state: StoreAddresses
) => {
  const addresses = deliveryAddresses.map(i => {
    const { code, name, id, contact_person: cp, number_of_employees } = i
    const address = `${i.address.street} ${i.address.postcode} ${i.address.city}`

    const actions = null
    const detail = null
    const contact_person = `${cp.last_name || ''} ${cp.first_name || ''} ${cp.email ||
      ''} ${cp.phone || ''}`
    const raw = i

    return {
      id,
      code,
      name,
      'address.street': address,
      number_of_employees,
      actions,
      detail,
      'contact_person.last_name': contact_person,
      raw,
      state,
    }
  })
  return addresses
}

export type TDeliveryAddressTableRow = UnwrapFromArray<ReturnType<typeof transformDataForTableRows>>

/**
 * Store holding data about branches and their addresses
 */
export class StoreAddresses {
  clientId: string

  branchId: string

  @observable deliveryAddresses: DeliveryAddress[] = []

  @observable deliveryAddressesFilters: DeliveryAddress[] = []

  @observable addressesLoading: boolean = false

  @observable DeliveryAddressesStore = new DeliveryAddressesStore(this.clientId, this.branchId)

  // Only SK for now
  @observable.ref country?: Country

  selecting = new TableSelectRowsStore()

  filters = new FilterExpressionBuilder<'search' | 'city' | 'deliveryAddress'>(
    [
      ['city', ''],
      ['search', ''],
      ['deliveryAddress', ''],
    ],
    `\${search}&&\${city}&&\${deliveryAddress}`
  )

  pagination = new TablePaginationStore({ perPage: 10 })

  sorting = new TableSortStore([['name', 'asc']])

  @observable.ref reloadRef = {}

  constructor(clientId: string, branchId: string) {
    this.clientId = clientId
    this.branchId = branchId
  }

  async fetchDeliveryAddresses(params?: this['params']) {
    this.addressesLoading = true

    try {
      let paramsLocal = params

      if (paramsLocal?.filter) {
        paramsLocal = {
          ...paramsLocal,
          filter: paramsLocal.filter.substring(1, paramsLocal.filter.length - 1),
        }
      }

      if (paramsLocal?.desc) {
        paramsLocal = {
          ...paramsLocal,
          sort_fields_desc: paramsLocal.desc,
        }
        delete paramsLocal.desc
      }

      if (paramsLocal?.sort) {
        paramsLocal = {
          ...paramsLocal,
          sort_fields: paramsLocal.sort,
        }
        delete paramsLocal.sort
      }

      // Fetch params - page data
      const fetchParams: any = params
        ? {
            ...paramsLocal,
            per_page: params.per_page,
          }
        : {}

      // Fetch params - all ids
      const fetchParamsAllIds = {
        ...(fetchParams?.filter ? { filter: fetchParams.filter } : {}),
        fields: 'id',
        per_page: maxPerPage,
      }

      // Parallel fetch of detailed page data and all ids
      const [resDeliveryAddresses, resAllIds] = await Promise.all([
        clientsApi.postDeliveryAddresses(this.clientId, this.branchId, fetchParams),
        clientsApi.postDeliveryAddresses(this.clientId, this.branchId, fetchParamsAllIds),
      ])

      // Process data - page data
      this.deliveryAddresses = resDeliveryAddresses.data.data!
      if (this.deliveryAddressesFilters.length === 0) {
        this.deliveryAddressesFilters = resDeliveryAddresses.data.data!
      }

      const pageIds = resDeliveryAddresses.data.data!.map(a => a.id)
      this.setPageIds(pageIds)

      // Process data - all ids
      const allIds = resAllIds.data.data!.map(a => a.id)
      this.setAllIds(allIds)

      // Rest
      this.pagination.objectCount = resDeliveryAddresses.data.pagination.object_count || 0
      this.addressesLoading = false
    } catch (error) {
      this.addressesLoading = false
      throw error
    }

    return this.deliveryAddresses
  }

  async fetchCountries() {
    const res = await countriesApi.getCountries({
      // SK in base64 hotfix
      params: { per_page: 1, filter: `"code=U0s="` },
    })
    this.country = get(res.data.data, 0)
    return this.country
  }

  async fetchAllDeliveryAddresses() {
    try {
      const res = await clientsApi.postDeliveryAddresses(this.clientId, this.branchId, {
        per_page: maxPerPage,
        sort_fields: 'name',
      })
      return res.data.data
    } catch (err) {
      logError(err, errorDuringFetch)
      throw err
    }
  }

  async fetchDeliveryAddressesTowns() {
    try {
      const res = await clientsApi.getDeliveryAddressesTowns(this.clientId)
      return res.data
    } catch (err) {
      logError(err, errorDuringFetch)
      throw err
    }
  }

  async fetchClientBranchesExport(params = this.params) {
    await this.DeliveryAddressesStore.fetchClientBranchesExport({
      ...params,
      per_page: 900719925,
    })
  }

  async fetchClientBranchesExportBulk() {
    await this.DeliveryAddressesStore.fetchClientBranchesExportBulk({
      ids: this.selecting.selected,
    })
  }

  setPageIds(ids: string[]) {
    this.selecting.pageIds = ids
  }

  setAllIds(ids: string[]) {
    this.selecting.allIds = ids
  }

  @computed get watch() {
    return [
      this.pagination.page,
      this.pagination.perPage,
      this.sorting.sortDefsAsRecord,
      this.filters.querystring,
      this.reloadRef,
    ]
  }

  get params() {
    return {
      ...this.pagination.params,
      ...this.sorting.params,
      ...this.filters.params,
    }
  }

  @computed get data() {
    return transformDataForTableRows(this.deliveryAddresses, this)
  }

  @computed get isFilteredAndNoData() {
    return this.filters.isAnyFilterActive && this.data.length === 0
  }

  @computed get hasNoData() {
    return this.filters.isNotDirty && this.data.length === 0
  }
}
