import { FieldState, FormState } from 'formstate'
import {
  required,
  postalCode,
  email,
  mobilePhone,
  maxLength,
  noWhiteSpaces,
} from '../../../common/utils/forms'

type Unwrap<T> = { [K in keyof T]: T[K] extends FieldState<infer U> ? U : T[K] }

type TFields = FormAddAddress['fields']

type TFieldsSerialized = Unwrap<TFields>

export class FormAddAddress {
  fields = {
    addressId: new FieldState('').validators(required, maxLength(10)),

    addressName: new FieldState('').validators(required, maxLength(64)),

    street: new FieldState('').validators(required, maxLength(32)),

    street2: new FieldState('').validators(maxLength(32)),

    zip: new FieldState('').validators(required, postalCode),

    city: new FieldState('').validators(required),

    contactName: new FieldState('').validators(required),

    contactSurName: new FieldState('').validators(required),

    contactEmail: new FieldState('').validators(required, email),

    contactPhone: new FieldState('').validators(number => mobilePhone(noWhiteSpaces(number))),

    responsibleName: new FieldState(''),

    responsibleSurName: new FieldState(''),

    responsibleEmail: new FieldState('').validators(email),

    responsiblePhone: new FieldState('').validators(number => mobilePhone(noWhiteSpaces(number))),
  }

  form = new FormState(this.fields)

  serialize = (): TFieldsSerialized => {
    return {
      addressId: this.fields.addressId.$,
      addressName: this.fields.addressName.$,
      street: this.fields.street.$,
      street2: this.fields.street2.$,
      zip: this.fields.zip.$,
      city: this.fields.city.$,
      contactName: this.fields.contactName.$.trim(),
      contactSurName: this.fields.contactSurName.$.trim(),
      contactEmail: this.fields.contactEmail.$,
      contactPhone: noWhiteSpaces(this.fields.contactPhone.$),
      responsibleName: this.fields.responsibleName.$.trim(),
      responsibleSurName: this.fields.responsibleSurName.$.trim(),
      responsibleEmail: this.fields.responsibleEmail.$,
      responsiblePhone: noWhiteSpaces(this.fields.responsiblePhone.$),
    }
  }
}
