import React, { useCallback, useEffect } from 'react'
import { debounce, uniqueId } from 'lodash-es'
import { observer, useLocalStore } from 'mobx-react-lite'
import { Trans } from '@lingui/macro'
import { Bar, BarItem, ButtonLink, Grid, GridCol, Icon } from '../../../common/ui-kit'
import { _, i18n } from '../../../common/utils/intl'

import { CommonDropdownFilter } from '../../../common/modules/data-table/filters/CommnoDropdownFilter'
import { SearchBar } from '../../../common/modules/data-table/filters/SearchBar'
import { StoreAddresses } from './StoreAddresses'
import { b64_to_string } from '../../../common/utils/b64'
import { createSearchFilter } from '../../../common/utils/filterExpressionBuilder'
import { useDidMount } from '../../../common/utils/hooks/useDidMount'
import useStorage from '../../../common/utils/hooks/useStorage'

export const TableActionsSecond: React.FC<{ addressesStore: StoreAddresses }> = observer(
  ({ addressesStore }) => {
    const { setItem, getItem, removeItem } = useStorage()

    const state = useLocalStore(() => ({
      filtersKey: '',

      cities: [] as { id: string; value: string }[],
      addressesOptions: [] as { id: string; value: string }[],

      get currentlyCheckedCitiesIds() {
        const cities = getItem('cities')
        if (cities) {
          return cities
            ?.replace(/address.city=/gi, '')
            .split('||')
            .map(i => Buffer.from(i, 'base64').toString('utf-8'))
        }
        return state.cities.map(i => i.id)
      },

      get showResetFiltersBtn() {
        return addressesStore.filters.isAnyFilterActive
      },

      get currentlyCheckedAddressesIds() {
        const addreses = getItem('addr.deliveryAddress')
        if (addreses) {
          return addreses?.replace(/id=/gi, '').split('||')
        }
        return state.addressesOptions.map(i => i.id)
      },

      changeResetFiltersBtnKey() {
        this.filtersKey = uniqueId()
        addressesStore.selecting.handleRowsReset()
        addressesStore.filters.clearAll()
        removeItem('addr.deliveryAddress')
        removeItem('addr.search')
        removeItem('cities')
      },
    }))

    const setFilter = (key: 'search' | 'city' | 'deliveryAddress', value: any) => {
      addressesStore.selecting.handleRowsReset() // reset selected rows on manual filter change
      addressesStore.filters.setFilter(key, value)
    }

    useEffect(() => {
      addressesStore.fetchDeliveryAddressesTowns().then(data => {
        state.cities = data.map(i => ({ id: i, value: i }))
      })
    }, [state, addressesStore])

    useDidMount(() => {
      addressesStore.fetchAllDeliveryAddresses().then(data => {
        state.addressesOptions = data?.map(i => ({ id: i.id, value: i.name! })) || []
      })

      if (getItem('addr.search')) {
        const fieldsToSearchIn = [
          'name',
          'code',
          'address.name',
          'address.street',
          'address.city',
          'contact_person.first_name',
          'contact_person.last_name',
          'contact_person.email',
          'contact_person.phone',
        ]
        setFilter('search', createSearchFilter(fieldsToSearchIn, getItem('addr.search')))
      }

      if (getItem('addr.deliveryAddress')) {
        setFilter('deliveryAddress', getItem('addr.deliveryAddress'))
      }

      if (getItem('cities')) {
        setFilter('city', getItem('cities'))
      }
    })

    const onSearch = useCallback(
      debounce((term?: string) => {
        if (!term) {
          setFilter('search', '')
          setItem('addr.search', '')
        } else {
          const fieldsToSearchIn = [
            'name',
            'code',
            'address.name',
            'address.street',
            'address.city',
            'contact_person.first_name',
            'contact_person.last_name',
            'contact_person.email',
            'contact_person.phone',
          ]
          setFilter('search', createSearchFilter(fieldsToSearchIn, term))
          setItem('addr.search', term)
        }
      }, 500),
      []
    )

    return (
      <Grid key={state.filtersKey}>
        <GridCol size={{ l: 4 }}>
          <SearchBar
            onChange={onSearch}
            defaultValue={b64_to_string(getItem('addr.search'))}
            placeholder={i18n.t`Hľadať`}
          />
        </GridCol>

        <GridCol size="fill">
          <Bar align="right" key="actions-right-1">
            <BarItem className="text-uppercase label">
              <Trans>Filter</Trans>:
            </BarItem>
            {state.addressesOptions.length && (
              <BarItem>
                <CommonDropdownFilter
                  defaultChecked={state.currentlyCheckedAddressesIds}
                  id="delivery-addresses"
                  data={state.addressesOptions}
                  labelAllItems={i18n.t`Adresy`}
                  labelSelectedItems={i18n.t`Adresy`}
                  onChange={checked => {
                    if (state.addressesOptions.length === checked.length) {
                      setFilter('deliveryAddress', '')
                      setItem('addr.deliveryAddress', '')
                    } else if (checked.length) {
                      setFilter('deliveryAddress', checked.map(i => `id=${i}`).join('||'))
                      setItem(
                        'addr.deliveryAddress',
                        addressesStore.filters.getFilter('deliveryAddress')!
                      )
                    } else {
                      // employeesStore.employees.setFromResponse({ data: [], pagination: {} })
                    }
                  }}
                />
              </BarItem>
            )}
            <BarItem>
              {state.cities.length && (
                <CommonDropdownFilter
                  defaultChecked={state.currentlyCheckedCitiesIds}
                  id="cities"
                  data={state.cities}
                  labelAllItems={i18n.t`Mestá`}
                  labelSelectedItems={i18n.t`Mestá`}
                  onChange={checked => {
                    if (state.cities.length === checked.length) {
                      setFilter('city', '')
                      setItem('cities', '')
                    } else if (checked.length) {
                      setFilter(
                        'city',
                        checked
                          .map(i => `address.city=${Buffer.from(i).toString('base64')}`)
                          .join('||')
                      )
                      setItem('cities', addressesStore.filters.getFilter('city')!)
                    } else {
                      // employeesStore.employees.setFromResponse({ data: [], pagination: {} })
                    }
                  }}
                />
              )}
            </BarItem>
            {state.showResetFiltersBtn && (
              <BarItem>
                <ButtonLink
                  space="condensed"
                  size="small"
                  onClick={() => {
                    state.changeResetFiltersBtnKey()
                  }}>
                  <Trans>Resetovať filter</Trans>
                  <Icon className="icon--right" name="close" size="medium" />
                </ButtonLink>
              </BarItem>
            )}
          </Bar>
        </GridCol>
      </Grid>
    )
  }
)
