import React, { useState, ReactElement } from 'react'
import { observer } from 'mobx-react-lite'
import { Department } from '@odiupsk/up-api-client'
import { t, Trans } from '@lingui/macro'
import { _ } from '../../../common/utils/intl'
import {
  DialogHeader,
  DialogContent,
  DialogFooter,
  Grid,
  GridCol,
  Button,
  ButtonPrimary,
  ButtonLayout,
  Input,
  ReachDialog,
} from '../../../common/ui-kit'
import { FormDepartment } from './FormDepartment'
import { fieldToInputProps } from '../../../common/utils/form/fieldToInputProps'
import { ApiClientsRepository } from '../../../common/api/ApiClientsRepository'
import { clientStore } from '../../ClientStore'
import { setErrorsFromResult } from '../../../common/utils/extractMessageFromAxiosError'

export const DeliveryContent = observer((props: { form: FormDepartment }) => {
  const { fields } = props.form

  return (
    <React.Fragment>
      <Grid className="mb-large">
        <GridCol size="fixed">
          <div className="text-counter">1</div>
        </GridCol>
        <GridCol size="auto">
          <h2 className="h3">
            <Trans>Základné informácie</Trans>
          </h2>
          <Grid>
            <GridCol size={{ m: 5 }}>
              <p className="text-color-grey-600">
                <Trans>Zadajte id a názov pomocou ktorého nájdete toto oddelenie v systéme.</Trans>
              </p>
            </GridCol>
            <GridCol size={{ m: 5 }}>
              <Input
                id="address-id"
                data-testid="address-id"
                label={_(t`ID oddelenia`)}
                {...fieldToInputProps(fields.code)}
                isRequired
              />
              <Input
                id="address-name"
                data-testid="address-name"
                label={_(t`Názov oddelenia`)}
                {...fieldToInputProps(fields.name)}
                isRequired
              />
            </GridCol>
          </Grid>
        </GridCol>
        <div className="border-bottom" />
      </Grid>
    </React.Fragment>
  )
})

export const ModalAddDepartment: React.FC<{
  children: ({ form }: { form: FormDepartment }) => ReactElement
  onSave?: (newDepartmentId: string) => void
  close: () => void
}> = observer(({ children, onSave, close }) => {
  const [form] = useState(() => new FormDepartment())
  const [active, setActive] = useState(true)
  const { id: clientId } = clientStore.client!

  return (
    <ReachDialog size="xxlarge" id={ModalAddDepartmentID} onDismiss={close}>
      <DialogHeader title={_(t`Pridanie oddelenia`)} />
      <DialogContent>{children({ form })}</DialogContent>
      <DialogFooter>
        <ButtonLayout direction="horizontal" className="align-items-right">
          <Button onClick={close}>
            <Trans>Zatvoriť</Trans>
          </Button>
          <ButtonPrimary
            isDisabled={!active}
            data-testid="btn-save"
            onClick={async () => {
              const { hasError } = await form.form.validate()
              if (hasError) return console.warn(form.form.error)

              const serialized = form.serialize()

              const body: Department = {
                client_id: clientId,
                code: serialized.code,
                name: serialized.name,
                id: '0',
              }

              setActive(false)
              try {
                const { id } = await ApiClientsRepository.saveDepartment(clientId, '0', body)

                if (onSave) {
                  onSave(id as string)
                }
                close()
              } catch (err) {
                setErrorsFromResult<Department>(
                  {
                    code: form.fields.code,
                    name: form.fields.name,
                  },
                  err
                )
              }
              setActive(true)
            }}>
            <Trans>Pridať</Trans>
          </ButtonPrimary>
        </ButtonLayout>
      </DialogFooter>
    </ReachDialog>
  )
})

ModalAddDepartment.displayName = 'ModalAddDepartment'

export const ModalAddDepartmentID = 'dialog-add-department'
