import React, { useContext, useState } from 'react'
import { Trans } from '@lingui/macro'
import { Grid, GridCol, ButtonLayout, Button, Icon } from '../../../common/ui-kit'
import { StoreAddresses } from './StoreAddresses'
import { DeliveryContent, ModalAddAddressID, ModalAddAddress } from './ModalAddAddress'
import { ModalImportAddresses } from './ModalImportAddresses'
import { useDialog } from '../../../common/utils/hooks/useDialog'
import { i18n } from '../../../common/utils/intl'
import { ReachConfirmDialog } from '../../../common/modules/modals/ConfirmModal'
import { ClientCtx } from '../../ClientStore'
import { appStore } from '../../../common/AppStore'

export function TableActionsFirst({ addressesStore }: { addressesStore: StoreAddresses }) {
  const dialog = useDialog()
  const dialogImport = useDialog()

  const modalError = useDialog()
  const [importErrorMessages, setImportErrorMessages] = useState([])

  const [exportLoading, setExportLoading] = useState(false)

  const clientDetail = useContext(ClientCtx)
  const isUserMaster = appStore.getIsUserMasterFromId(clientDetail.id)

  const handleDownload = async () => {
    setExportLoading(true)
    if (addressesStore.selecting.selected.length) {
      await addressesStore.fetchClientBranchesExportBulk().then(() => {
        setExportLoading(false)
      })
    } else {
      await addressesStore.fetchClientBranchesExport().then(() => {
        setExportLoading(false)
      })
    }
  }

  return (
    <Grid>
      <GridCol size="fill">
        <ButtonLayout key="actions-left-1" className="mb" direction={{ s: 'horizontal' }}>
          {isUserMaster && (
            <Button onClick={dialog.open} data-testid={ModalAddAddressID}>
              <Icon name="plus" size="medium" className="icon--left" />
              <Trans>Pridať</Trans>
            </Button>
          )}
          {isUserMaster && (
            <Button onClick={dialogImport.open}>
              <Icon name="upload" size="medium" className="icon--left" />
              <Trans>Importovať</Trans>
            </Button>
          )}
          <Button loaderProps={{ white: false }} isLoading={exportLoading} onClick={handleDownload}>
            <Icon name="download" size="medium" className="icon--left" />
            <Trans>Stiahnuť</Trans>
          </Button>
        </ButtonLayout>
      </GridCol>
      {dialog.isOpen && (
        <ModalAddAddress
          close={dialog.close}
          country={addressesStore.country!}
          onSave={() => {
            addressesStore.reloadRef = {}
          }}>
          {({ form }) => <DeliveryContent form={form} />}
        </ModalAddAddress>
      )}
      {dialogImport.isOpen && (
        <ModalImportAddresses
          store={addressesStore}
          close={dialogImport.close}
          onSave={() => {
            addressesStore.reloadRef = {}
          }}
          onImportFail={errorMessages => {
            dialogImport.close()
            setImportErrorMessages(errorMessages)
            modalError.open()
          }}
        />
      )}

      {modalError.isOpen && (
        <ReachConfirmDialog
          close={modalError.close}
          id="modal-import-error"
          buttonConfirmAction={async () => {
            modalError.close()
            dialogImport.open()
          }}
          message={
            <>
              <p>{i18n.t`Import neprebehol úspešne.`}</p>
              {!!(importErrorMessages.length > 0) &&
                importErrorMessages.map((message: { message: string }) => <p>{message.message}</p>)}
            </>
          }
          buttonConfirmText={i18n.t`Nahrať znovu`}
        />
      )}
    </Grid>
  )
}
