import React, { useEffect, useState } from 'react'
import { observer, useLocalStore } from 'mobx-react-lite'

import { css } from 'styled-components'
import { PageManageAddressesSkeleton } from './PageManageAddressesSkeleton'
import { Section } from '../../../common/ui-kit'
import { StoreAddresses } from './StoreAddresses'
import { TableAddresses } from './TableAddresses'
import { clientStore } from '../../ClientStore'

export const PageManageAddresses = observer(() => {
  const { clientId, branchId } = clientStore

  const [addressesStore] = useState(() => new StoreAddresses(clientId, branchId))

  const state = useLocalStore(() => ({
    isLoading: true,
  }))

  // reset pagination to first page on filter change
  useEffect(() => {
    addressesStore.pagination.page = 1
  }, [addressesStore.filters.querystring])

  useEffect(() => {
    state.isLoading = true
    addressesStore.fetchDeliveryAddresses(addressesStore.params).finally(() => {
      state.isLoading = false
    })

    addressesStore.fetchCountries()
  }, [addressesStore, state, addressesStore.watch])

  return (
    <Section
      size="medium"
      css={css`
        position: relative;
      `}>
      {!state.isLoading ? (
        <TableAddresses store={addressesStore} />
      ) : (
        <PageManageAddressesSkeleton />
      )}
    </Section>
  )
})

PageManageAddresses.displayName = 'PageManageAddresses'
