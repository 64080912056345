import { Department } from '@odiupsk/up-api-client'
import { observable, computed } from 'mobx'
import { clientsApi } from '../../../common/api'
import { FilterExpressionBuilder } from '../../../common/utils/filterExpressionBuilder'
import { TablePaginationStore } from '../../../common/modules/data-table/stores/TablePaginationStore'
import { TableSortStore } from '../../../common/modules/data-table/stores/TableSortStore'
import { UnwrapFromArray } from '../../../common/utils/types'
import { clientStore } from '../../ClientStore'
import { ApiClientsRepository } from '../../../common/api/ApiClientsRepository'
import { TableSelectRowsStore } from '../../../common/modules/data-table/stores/TableSelectRowsStore'
import { maxPerPage } from '../../../common/utils/maxPerPage'

export const transformDataForTableRows = (departments: Department[], state: StoreDepartments) => {
  return departments.map(i => {
    const { code, name, id, number_of_employees } = i
    const actions = null
    const detail = null
    const raw = i
    return { id, code, name, number_of_employees, actions, detail, raw, state }
  })
}

export type TDepartmentTableRow = UnwrapFromArray<ReturnType<typeof transformDataForTableRows>>

/**
 * Store holding data about departments
 */
export class StoreDepartments {
  clientId: string

  @observable.ref departments: Department[] = []

  @observable loadingDepartments: boolean = false

  // @computed get selectedIds() {
  // }

  filters = new FilterExpressionBuilder<'search' | 'city'>(
    [
      ['city', ''],
      ['search', ''],
    ],
    `\${search}&&\${city}`
  )

  pagination = new TablePaginationStore({ perPage: 10 })

  sorting = new TableSortStore([['name', 'asc']])

  selecting = new TableSelectRowsStore()

  @observable.ref reloadRef = {}

  constructor() {
    this.clientId = clientStore.clientId
  }

  async fetchDepartments(params = this.params) {
    this.loadingDepartments = true

    try {
      const res = await clientsApi.getDepartments(this.clientId, {
        params,
      })

      this.departments = res.data.data!
      this.pagination.objectCount = res.data.pagination.object_count || 0
      this.setPageIds(res.data.data!.map(a => a.id) || [])

      this.loadingDepartments = false
    } catch (error) {
      this.loadingDepartments = false
      throw error
    }
  }

  async fetchAllDepartmentsIds(params?: any) {
    const res = await ApiClientsRepository.fetchDepartments(this.clientId, {
      ...params,
      fields: 'id',
      per_page: maxPerPage,
    })
    this.setAllIds(res.data?.map(a => a.id) || [])
  }

  async fetchClientDepartmentsExport(params = this.params) {
    await ApiClientsRepository.fetchClientDepartmentsExport(this.clientId, {
      ...params,
      per_page: 900719925,
      page: 1,
    })
  }

  async fetchClientDepartmentsExportBulk() {
    await ApiClientsRepository.fetchClientDepartmentsExportBulk(this.clientId, {
      ids: this.selecting.selected,
    })
  }

  setPageIds(ids: string[]) {
    this.selecting.pageIds = ids
  }

  setAllIds(ids: string[]) {
    this.selecting.allIds = ids
  }

  @computed get watch() {
    return [
      this.pagination.page,
      this.pagination.perPage,
      this.sorting.sortDefsAsRecord,
      this.filters.querystring,
      this.reloadRef,
    ]
  }

  get params() {
    return {
      ...this.pagination.params,
      ...this.filters.params,
      ...this.sorting.params,
    }
  }

  @computed get data() {
    return transformDataForTableRows(this.departments, this)
  }

  @computed get isFilteredAndNoData() {
    return this.filters.isAnyFilterActive && this.data.length === 0
  }

  @computed get hasNoData() {
    return this.filters.isNotDirty && this.data.length === 0
  }
}
