import React, { useState, ReactElement } from 'react'
import { t, Trans } from '@lingui/macro'
import { observer } from 'mobx-react-lite'
import { DeliveryAddress, Country } from '@odiupsk/up-api-client'
import { _ } from '../../../common/utils/intl'
import {
  DialogHeader,
  DialogContent,
  DialogFooter,
  Grid,
  GridCol,
  Button,
  ButtonPrimary,
  ButtonLayout,
  Input,
  ReachDialog,
} from '../../../common/ui-kit'
import { FormAddAddress } from './FormAddAddress'
import { fieldToInputProps } from '../../../common/utils/form/fieldToInputProps'
import { ApiClientsRepository } from '../../../common/api/ApiClientsRepository'
import { clientStore } from '../../ClientStore'
import { setErrorsFromResult } from '../../../common/utils/extractMessageFromAxiosError'

export const DeliveryContent = observer((props: { form: FormAddAddress }) => {
  const { fields } = props.form

  return (
    <React.Fragment>
      <Grid className="mb-large">
        <GridCol size="fixed">
          <div className="text-counter">1</div>
        </GridCol>
        <GridCol size="auto">
          <h2 className="h3">
            <Trans>Základné informácie</Trans>
          </h2>
          <Grid>
            <GridCol size={{ m: 5 }}>
              <p className="text-color-grey-600">
                <Trans>Zadajte id a názov pomocou ktorého nájdete túto adresu v systéme.</Trans>
              </p>
            </GridCol>
            <GridCol size={{ m: 5 }}>
              <Input
                id="address-id"
                data-testid="input-address-id"
                label={_(t`ID adresy`)}
                {...fieldToInputProps(fields.addressId)}
                isRequired
                onKeyPress={e => {
                  if (e.currentTarget.value.length >= 10) {
                    e.preventDefault()
                    e.stopPropagation()
                  }
                }}
              />
            </GridCol>
          </Grid>
        </GridCol>
        <div className="border-bottom" />
      </Grid>
      <Grid className="mb-large">
        <GridCol size="fixed">
          <div className="text-counter">2</div>
        </GridCol>
        <GridCol size="auto">
          <h2 className="h3">
            <Trans>Adresa doručenia</Trans>
          </h2>
          <Grid>
            <GridCol size={{ m: 5 }}>
              <p className="text-color-grey-600">
                <Trans>
                  Túto adresu si budete môcť vybrať počas objednávky jednoduchým spôsobom.
                </Trans>
              </p>
            </GridCol>
            <GridCol size={{ m: 5 }}>
              <Input
                id="address-name"
                data-testid="input-address-name"
                label={_(t`Pomenovanie adresy`)}
                {...fieldToInputProps(fields.addressName)}
                isRequired
              />
              <Input
                id="street"
                data-testid="input-street"
                label={_(t`Ulica`)}
                isRequired
                {...fieldToInputProps(fields.street)}
              />
              <Input
                id="street2"
                type="text"
                label={<Trans>Upresnenie adresy</Trans>}
                {...fieldToInputProps(fields.street2)}
              />
              <Input
                id="zip"
                data-testid="input-zip"
                label={_(t`PSČ`)}
                isRequired
                {...fieldToInputProps(fields.zip)}
              />
              <Input
                id="city"
                data-testid="input-city"
                label={_(t`Mesto`)}
                isRequired
                {...fieldToInputProps(fields.city)}
              />
            </GridCol>
          </Grid>
        </GridCol>
        <div className="border-bottom" />
      </Grid>
      <Grid className="mb-large">
        <GridCol size="fixed">
          <div className="text-counter">3</div>
        </GridCol>
        <GridCol size="auto">
          <h2 className="h3">
            <Trans>Kontaktná osoba</Trans>
          </h2>
          <Grid>
            <GridCol size={{ m: 5 }}>
              <p className="text-color-grey-600">
                <Trans>Táto osoba bude kontaktovaná v&nbsp;prípade potreby pri doručení.</Trans>
              </p>
            </GridCol>
            <GridCol size={{ m: 5 }}>
              <Input
                id="contact-name"
                data-testid="input-contact-name"
                label={_(t`Meno`)}
                isRequired
                {...fieldToInputProps(fields.contactName)}
              />
              <Input
                id="contact-surname"
                data-testid="input-contact-surname"
                label={_(t`Priezvisko`)}
                isRequired
                {...fieldToInputProps(fields.contactSurName)}
              />
              <Input
                id="contact-email"
                data-testid="input-contact-email"
                label={_(t`Email`)}
                isRequired
                {...fieldToInputProps(fields.contactEmail)}
              />
              <Input
                id="contact-phone"
                data-testid="input-contact-phone"
                label={_(t`Telefónne číslo`)}
                {...fieldToInputProps(fields.contactPhone)}
              />
            </GridCol>
          </Grid>
        </GridCol>
        <div className="border-bottom" />
      </Grid>
      <Grid className="mb-large">
        <GridCol size="fixed">
          <div className="text-counter">4</div>
        </GridCol>
        <GridCol size="auto">
          <h2 className="h3">
            <Trans>Zodpovednosť za objedávky</Trans>
          </h2>
          <Grid>
            <GridCol size={{ m: 5 }}>
              <p className="text-color-grey-600">
                <Trans>Osoba, ktorá je zodpovedná za spravovanie objednávok</Trans>
              </p>
            </GridCol>
            <GridCol size={{ m: 5 }}>
              <Input
                id="responsible-name"
                data-testid="input-responsible-name"
                label={_(t`Meno`)}
                {...fieldToInputProps(fields.responsibleName)}
              />
              <Input
                id="responsible-surname"
                data-testid="input-responsible-surname"
                label={_(t`Priezvisko`)}
                {...fieldToInputProps(fields.responsibleSurName)}
              />
              <Input
                id="responsible-email"
                data-testid="input-responsible-email"
                label={_(t`Email`)}
                {...fieldToInputProps(fields.responsibleEmail)}
              />
              <Input
                id="responsible-phone"
                data-testid="input-responsible-phone"
                label={_(t`Telefónne číslo`)}
                {...fieldToInputProps(fields.responsiblePhone)}
              />
            </GridCol>
          </Grid>
        </GridCol>
        <div className="border-bottom" />
      </Grid>
    </React.Fragment>
  )
})

DeliveryContent.displayName = 'DeliveryContent'

export const ModalAddAddress: React.FC<{
  country: Country
  onSave?: (id: string) => void
  children: ({ form }: { form: FormAddAddress }) => ReactElement
  close: () => void
}> = observer(({ children, country, onSave, close }) => {
  const [form] = useState(() => new FormAddAddress())
  const [active, setActive] = useState(true)

  const { clientId, branchId } = clientStore

  return (
    <ReachDialog size="xxlarge" id={ModalAddAddressID} onDismiss={close}>
      <DialogHeader title={_(t`Pridanie adresy doručenia`)} />
      <DialogContent>{children({ form })}</DialogContent>
      <DialogFooter>
        <ButtonLayout direction="horizontal" className="align-items-right">
          <Button onClick={close}>
            <Trans>Zatvoriť</Trans>
          </Button>
          <ButtonPrimary
            isDisabled={!active}
            data-testid="btn-save"
            onClick={async () => {
              const { hasError } = await form.form.validate()
              if (hasError) return console.warn(form.form.error)

              const serialized = form.serialize()

              const body: DeliveryAddress = {
                address: {
                  id: '0',
                  city: serialized.city,
                  postcode: serialized.zip,
                  street: serialized.street,
                  street2: serialized.street2,
                  country,
                },
                id: '0',
                code: serialized.addressId,
                name: serialized.addressName,
                branch_id: branchId,
                contact_person: {
                  id: '0',
                  client_id: clientId,
                  first_name: serialized.contactName,
                  last_name: serialized.contactSurName,
                  phone: serialized.contactPhone,
                  email: serialized.contactEmail,
                },
                responsible_person_for_order: {
                  id: '0',
                  client_id: clientId,
                  first_name: serialized.responsibleName,
                  last_name: serialized.responsibleSurName,
                  phone: serialized.responsiblePhone,
                  email: serialized.responsibleEmail,
                },
              }
              setActive(false)

              try {
                const res = await ApiClientsRepository.saveDeliveryAddress(
                  clientId,
                  branchId,
                  '0',
                  body
                )
                if (onSave) onSave(res.id as string)
                close()
              } catch (err) {
                setErrorsFromResult<DeliveryAddress>(
                  {
                    code: form.fields.addressId,
                  },
                  err
                )
              }
              setActive(true)
            }}>
            <Trans>Pridať</Trans>
          </ButtonPrimary>
        </ButtonLayout>
      </DialogFooter>
    </ReachDialog>
  )
})

ModalAddAddress.displayName = 'ModalAddAddress'

export const ModalAddAddressID = 'dialog-add-delivery-place'
