import React, { useRef } from 'react'
import { useLocalStore, observer } from 'mobx-react-lite'
import { Trans } from '@lingui/macro'
import {
  DialogHeader,
  DialogContent,
  DialogFooter,
  Bar,
  BarItem,
  Grid,
  GridCol,
  Button,
  ButtonLink,
  ButtonPrimary,
  ButtonLayout,
  Icon,
  FileUpload,
  FINE_UPLOADER_EXCEL,
  ReachDialog,
} from '../../../common/ui-kit'
import { fileToBase64 } from '../../../common/utils/fileToBase64'
import { ApiClientsRepository } from '../../../common/api/ApiClientsRepository'
import { i18n } from '../../../common/utils/intl'
import { downloadBlob } from '../../../common/utils/downloadToDisk'
import { clientStore } from '../../ClientStore'
import { extractAllMessagesFromAxiosError } from '../../../common/utils/extractMessageFromAxiosError'

export const ModalImportDepartments: React.FC<{
  onFinish?(): void
  close(): void
  onImportFail: (errorMessages: []) => void
}> = observer(props => {
  const { close, onFinish = () => ({}), onImportFail } = props
  const uploaderRef = useRef<any>()

  const state = useLocalStore(() => ({
    isUploading: false,
    hasFile: false,
    downloadTemplateLoading: false,
  }))

  return (
    <ReachDialog id={ModalImportDepartmentsID} onDismiss={close}>
      <DialogHeader title={i18n.t`Import údajov`} />
      <DialogContent>
        <Bar direction="vertical">
          <BarItem>
            <Grid>
              <GridCol size={2}>
                <div className="text-counter">1</div>
              </GridCol>
              <GridCol size={9}>
                <h3 className="h5">
                  <Trans>Stiahnutie</Trans>
                </h3>
                <p className="text-color-grey-600">
                  <Trans>
                    Stiahnite si vzorový súbor a vyplňte do neho údaje o vašich oddeleniach
                  </Trans>
                </p>
                <ButtonLink
                  size="small"
                  space="condensed"
                  isLoading={state.downloadTemplateLoading}
                  isDisabled={state.downloadTemplateLoading}
                  onClick={async () => {
                    try {
                      state.downloadTemplateLoading = true
                      const response = await ApiClientsRepository.fetchDepartmentsImportTemplate(
                        clientStore.clientId
                      )
                      downloadBlob(response)
                      state.downloadTemplateLoading = false
                    } catch (err) {
                      console.log(err)
                      state.downloadTemplateLoading = false
                    }
                  }}>
                  {!state.downloadTemplateLoading && (
                    <Icon name="download" size="medium" className="icon--left" />
                  )}
                  <span>
                    <Trans>Stiahnuť vzorový súbor</Trans>
                  </span>
                </ButtonLink>
              </GridCol>
            </Grid>
          </BarItem>
          <BarItem>
            <Grid>
              <GridCol size={2}>
                <div className="text-counter">2</div>
              </GridCol>
              <GridCol size={9}>
                <h3 className="h5">
                  <Trans>Nahratie</Trans>
                </h3>
                <p className="text-color-grey-600">
                  <Trans>
                    Po vyplnení vzorového súboru ho môžete nahrať sem. Jeho potvrdením sa nové
                    oddelenia ihneď zaevidujú.
                  </Trans>
                </p>
                <FileUpload
                  uploaderRef={uploaderRef}
                  id="employee-import"
                  onStateChange={val => {
                    state.hasFile = val.fileId != null
                  }}
                  fineUploaderOptions={{
                    validation: {
                      ...FINE_UPLOADER_EXCEL,
                    },
                  }}
                />
              </GridCol>
            </Grid>
          </BarItem>
        </Bar>
      </DialogContent>
      <DialogFooter>
        <ButtonLayout direction="horizontal" className="align-items-right">
          <Button onClick={close}>
            <Trans>Zatvoriť</Trans>
          </Button>
          <ButtonPrimary
            isDisabled={state.isUploading || !state.hasFile}
            isLoading={state.isUploading}
            onClick={async () => {
              state.isUploading = true
              const fu = uploaderRef.current
              const files = fu.methods.getUploads({ status: fu.qq.status.SUBMITTED })
              for (const f of files) {
                // eslint-disable-next-line no-await-in-loop
                const b64 = await fileToBase64(f.file)
                const payload = {
                  file_name: f.name,
                  byte_data: b64,
                }

                try {
                  // eslint-disable-next-line no-await-in-loop
                  await ApiClientsRepository.importDepartments(clientStore.clientId, payload, false)
                  onFinish()
                } catch (error) {
                  console.log('sss', error)
                  console.log('sss', extractAllMessagesFromAxiosError(error))
                  close()
                  onImportFail(extractAllMessagesFromAxiosError(error))
                }
              }
              state.isUploading = false
            }}>
            <Trans>Potvrdiť</Trans>
          </ButtonPrimary>
        </ButtonLayout>
      </DialogFooter>
    </ReachDialog>
  )
})

export const ModalImportDepartmentsID = 'dialog-import-departments'

ModalImportDepartments.displayName = 'ModalImportDepartments'
