import React from 'react'
import ContentLoader from 'react-content-loader'
import { Loader } from '../../../common/ui-kit'

export const PageManageDepartmentsSkeleton = () => {
  return (
    <>
      <Loader
        size="large"
        loaderWrapperProps={{
          style: {
            position: 'absolute',
            top: '200px',
            justifyContent: 'center',
            width: '100%',
          },
        }}
      />
      <ContentLoader
        height={760}
        width={1190}
        speed={2}
        primaryColor="#f3f3f3"
        secondaryColor="#ecebeb">
        <rect x="0" y="15" width="125" height="40" rx="3" ry="3" />
        <rect x="0" y="70" width="475" height="35" rx="3" ry="3" />

        <rect x="0" y="120" width="1190" height="45" rx="3" ry="3" />
        <rect x="0" y="170" width="1190" height="45" rx="3" ry="3" />
        <rect x="0" y="220" width="1190" height="45" rx="3" ry="3" />
        <rect x="0" y="270" width="1190" height="45" rx="3" ry="3" />
        <rect x="0" y="320" width="1190" height="45" rx="3" ry="3" />
        <rect x="0" y="370" width="1190" height="45" rx="3" ry="3" />
        <rect x="0" y="420" width="1190" height="45" rx="3" ry="3" />
        <rect x="0" y="470" width="1190" height="45" rx="3" ry="3" />
        <rect x="0" y="520" width="1190" height="45" rx="3" ry="3" />
        <rect x="0" y="570" width="1190" height="45" rx="3" ry="3" />
        <rect x="0" y="620" width="1190" height="45" rx="3" ry="3" />
        <rect x="0" y="670" width="1190" height="45" rx="3" ry="3" />
        <rect x="0" y="720" width="1190" height="45" rx="3" ry="3" />
        <rect x="0" y="770" width="1190" height="45" rx="3" ry="3" />
      </ContentLoader>
    </>
  )
}
