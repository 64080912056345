import { FieldState, FormState } from 'formstate'
import { UnwrapFromFieldState } from '../../../common/utils/types'
import { required } from '../../../common/utils/forms'

type TFields = FormDepartment['fields']

type TFieldsSerialized = UnwrapFromFieldState<TFields>

export class FormDepartment {
  fields = {
    code: new FieldState('').enableAutoValidation().validators(required),
    name: new FieldState('').enableAutoValidation().validators(required),
  }

  form = new FormState(this.fields)

  serialize = (): TFieldsSerialized => {
    return {
      code: this.fields.code.$,
      name: this.fields.name.$,
    }
  }
}
