import React, { useEffect, useState } from 'react'
import { observer, useLocalStore } from 'mobx-react-lite'

import { css } from 'styled-components'
import { PageManageDepartmentsSkeleton } from './PageManageDepartmentsSkeleton'
import { Section } from '../../../common/ui-kit'
import { StoreDepartments } from './StoreDepartments'
import { TableDepartments } from './TableDepartments'

export const PageManageDepartments = observer(() => {
  const [store] = useState(() => new StoreDepartments())

  const state = useLocalStore(() => ({
    isLoading: true,
  }))

  // reset pagination to first page on filter change
  useEffect(() => {
    store.pagination.page = 1
  }, [store.filters.querystring])

  useEffect(() => {
    state.isLoading = true
    store.fetchDepartments().finally(() => {
      state.isLoading = false
    })

    store.fetchAllDepartmentsIds()
  }, [store, state, store.watch])

  return (
    <Section
      size="medium"
      css={css`
        position: relative;
      `}>
      {!state.isLoading ? <TableDepartments store={store} /> : <PageManageDepartmentsSkeleton />}
    </Section>
  )
})
