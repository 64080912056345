import React, { useCallback } from 'react'
import debounce from 'lodash/debounce'
import { observer } from 'mobx-react-lite'
import { Grid, GridCol } from '../../../common/ui-kit'
import { _, i18n } from '../../../common/utils/intl'

import { SearchBar } from '../../../common/modules/data-table/filters/SearchBar'
import { StoreDepartments } from './StoreDepartments'
import { b64_to_utf8 } from '../../../common/utils/b64'
import { createSearchFilter } from '../../../common/utils/filterExpressionBuilder'
import { useDidMount } from '../../../common/utils/hooks/useDidMount'
import useStorage from '../../../common/utils/hooks/useStorage'

export const createCitiesFilter = (cities: string[]) => (data: any[]) => {
  return data.filter(i => {
    return cities.includes(i.raw.address.city)
  })
}

export const TableActionsSecond: React.FC<{
  store: StoreDepartments
}> = observer(({ store }) => {
  const { setItem, getItem } = useStorage()

  const setFilter = (key: 'search' | 'city', value: any) => {
    store.selecting.handleRowsReset() // reset selected rows on manual filter change
    store.filters.setFilter(key, value)
  }

  useDidMount(() => {
    if (getItem('dep.search')) {
      const fieldsToSearchIn = ['name', 'code']
      setFilter('search', createSearchFilter(fieldsToSearchIn, getItem('dep.search')))
    }
  })

  const onSearch = useCallback(
    debounce((term?: string) => {
      if (!term) {
        setFilter('search', '')
        setItem('dep.search', '')
      } else {
        const fieldsToSearchIn = ['name', 'code']
        setFilter('search', createSearchFilter(fieldsToSearchIn, term))
        setItem('dep.search', term)
      }
    }, 500),
    []
  )

  return (
    <Grid>
      <GridCol size={{ l: 4 }}>
        <SearchBar
          onChange={onSearch}
          defaultValue={b64_to_utf8(getItem('dep.search'))}
          placeholder={i18n.t`Hľadať`}
        />
      </GridCol>
    </Grid>
  )
})

TableActionsSecond.displayName = 'TableActionsSecond'
